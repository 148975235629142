import gql from 'graphql-tag';

export const UPDATE_COLLECTION_SLOT_MUTATION = gql`
  mutation ($param: CollectionSlotUpdateParam!) {
    updateCollectionSlot(param: $param) {
      id
      description
      maximumCountOfCollection
      collectionType
      conditions {
        id
        maxImageSize
        imageWidth
        imageHeight
        platform
      }
    }
  }
`;

export const CREATE_COLLECTION_SLOT_MUTATION = gql`
  mutation ($param: CollectionSlotCreateParam!) {
    createCollectionSlot(param: $param) {
      id
      description
      maximumCountOfCollection
      collectionType
      conditions {
        id
        maxImageSize
        imageWidth
        imageHeight
        platform
      }
    }
  }
`;

export const DELETE_COLLECTION_SLOT_MUTATUIN = gql`
  mutation ($id: ID!) {
    deleteCollectionSlot(id: $id) {
      success
      message
    }
  }
`;

export const UPDATE_COLLECTION_VISIBLE_STATES_MUTATION = gql`
  mutation ($slotId: ID!, $visibleStates: [CollectionVisibility!]!) {
    updateCollectionVisibleStates(
      slotId: $slotId
      visibleStates: $visibleStates
    ) {
      success
      message
      collections {
        id
        title
        description
        homeShowStartAt
        homeShowEndAt
        listShowStartAt
        listShowEndAt
        status
        isShownAtHome
        seqNo
        slot {
          id
        }
        images {
          platform
          content {
            id
          }
        }
        products {
          totalCount
          edges {
            node {
              id
            }
            cursor
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;

export const UPDATE_PRODUCT_COLLECTION = gql`
  mutation ($param: ProductCollectionUpdateParam!) {
    updateProductCollection(param: $param) {
      id
      title
      description
      homeShowStartAt
      homeShowEndAt
      listShowStartAt
      listShowEndAt
      status
      isShownAtHome
      seqNo
      slot {
        id
      }
      images {
        platform
        content {
          id
        }
      }
      products {
        totalCount
        edges {
          node {
            id
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const DELETE_COLLECTION_MUTATION = gql`
  mutation ($id: ID!) {
    deleteCollection(id: $id) {
      success
      message
      collections {
        id
        title
        description
        homeShowStartAt
        homeShowEndAt
        listShowStartAt
        listShowEndAt
        status
        isShownAtHome
        seqNo
        slot {
          id
        }
        images {
          platform
          content {
            id
          }
        }
        products {
          totalCount
          edges {
            node {
              id
            }
            cursor
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;

export const CREATE_PRODUCT_COLLECTION = gql`
  mutation ($param: ProductCollectionCreateParam!) {
    createProductCollection(param: $param) {
      id
      title
      description
      homeShowStartAt
      homeShowEndAt
      listShowStartAt
      listShowEndAt
      status
      isShownAtHome
      seqNo
      slot {
        id
      }
      images {
        platform
        content {
          id
        }
      }
      products {
        totalCount
        edges {
          node {
            id
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const UPDATE_THEME_COLLECTION_MUTATION = gql`
  mutation ($param: ThemeCollectionUpdateParam!) {
    updateThemeCollection(param: $param) {
      id
      title
      description
      homeShowStartAt
      homeShowEndAt
      listShowStartAt
      listShowEndAt
      status
      isShownAtHome
      seqNo
      slot {
        id
      }
      images {
        platform
        content {
          id
        }
      }
      products {
        totalCount
        edges {
          node {
            id
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const CREATE_THEME_COLLECTION_MUTATION = gql`
  mutation ($param: ThemeCollectionCreateParam!) {
    createThemeCollection(param: $param) {
      id
      title
      description
      homeShowStartAt
      homeShowEndAt
      listShowStartAt
      listShowEndAt
      status
      isShownAtHome
      seqNo
      slot {
        id
      }
      images {
        platform
        content {
          id
        }
      }
      products {
        totalCount
        edges {
          node {
            id
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;
